import React from "react";
import { useParams } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from "./components/Navbar";
import LedenEditForm from "./components/LedenEditForm";
import Footer from "./components/Footer";

export default function Content() {


  let { id } = useParams();
  return (        
    <div className="page-container">
      <MDBContainer>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column mb-3 w-100">
          <div className="p-2 w-100 navwrapper bg-light"><Navbar /></div>
            <div className="w-100 pageWrapper">
            <div className="page">
                <div className="title"><h1>Leden</h1></div>
                <b>Bewerken van een nieuw lid</b><br />
                In onderstaande formulier kunnen wijzigingen worden ingevoerd. Het lid kan op deze pagina ook verwijderd worden. <br />
                <i>In het geval een lid onbedoeld is verwijderd kan dit op de achtergrond nog wel ongedaan gemaakt worden.</i><br />
                <div className="content"><LedenEditForm id={id}/></div>
              </div>
            </div>
        </div>
      </div>
      <Footer />
      </MDBContainer>
      
    </div>
  );
}