import React from "react";
import { MDBContainer } from 'mdb-react-ui-kit';
import KerkdienstgemistTerugkijken from "./components/KerkdienstgemistTerugkijken";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

export default function Diensten() {
    return (
    <div className="page-container">
      <MDBContainer>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column mb-3 w-100">
          <div className="p-2 w-100 navwrapper bg-light"><Navbar /></div>
            <div className="w-100 pageWrapper"><KerkdienstgemistTerugkijken /></div>
        </div>
      </div>
      <Footer />
      </MDBContainer>
     
    </div>

        
    )
}