import React, { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import {StyleSheet, Text, View, TextInput, Pressable} from 'react-native';
import { useKeycloak } from '@react-keycloak/web';

const styles = StyleSheet.create({
  
  container: {
    display: 'flex',
   
  },
  inputWrap: {
    flex: 1,
    flexDirection: "row",
    borderColor: "#cccccc",
    justifyContent: 'center',
    borderBottomWidth: 1,
    marginBottom: 10
  },
  submit: {
    height: '50px',
    width: '125px',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 3,
    marginTop: 15,
    textAlign: 'center',
    margin: '10px',
    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
    backgroundColor: 'green',
    padding: '5px',
  },  

  text: {
    fontSize: 16,
    color: 'white',
    fontStyle: 'bold',
  },

  delete: {
    height: '50px',
    width: '125px',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 3,
    marginTop: 15,
    margin: '10px',
    textAlign: 'center',

    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
    backgroundColor: 'red',
    padding: '5px',
  },

  cancel: {
    borderRadius: '4px',
    backgroundColor: 'gray',
    marginBottom: 10,
    width:80,
    alignItems: 'center',
    justifyContent: 'center',
  },

  cancelText: {
    fontSize: 16,
    color: 'white',
    fontStyle: 'bold',
  },

 
});

export default function LedenEditForm({id}) {
  const {keycloak} = useKeycloak();
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL; 

  function load(id){
    var AuthorizationHeader = 'Bearer ' + keycloak.token; 

    const apiUrl = apiBaseUrl+"/leden/"+id;
      try {
      fetch(apiUrl, {
          method: 'GET',
          withCredentials: true,
        
          headers: {
              'Authorization': AuthorizationHeader,
              'Content-Type': 'application/json'
          }}).then((res) => res.json())
        .then((repos) => {
          setLid(repos); 
                
        });
      } catch {}
  }
  
  function updateLid(){
    console.log(lid);
    saveLid();
  }

  function saveLid() {
    try {
    var AuthorizationHeader = 'Bearer ' + keycloak.token; 

    const apiUrl = apiBaseUrl+"/leden/"+id;
    console.log(lid);
      fetch(apiUrl, {
          method: 'POST',
          withCredentials: true,
        
          headers: {
              'Authorization': AuthorizationHeader,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(lid)
          },
          alert('Wijzigingen zijn opgeslagen.'),
          navigate("/Leden/Adressen")
      );
    } catch(error) {
      console.log(error);
      alert('Opslaan is mislukt')
    }
  }
  
  function verwijderLid() {
    try {
    var AuthorizationHeader = 'Bearer ' + keycloak.token; 

    const apiUrl = apiBaseUrl+"/leden/delete/"+id;
    console.log(lid);
      fetch(apiUrl, {
          method: 'POST',
          withCredentials: true,
        
          headers: {
              'Authorization': AuthorizationHeader,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(lid)
          },
          alert('Het lid is verwijderd.'),
          navigate("/Leden/Adressen")
      );
    } catch(error) {
      console.log(error);
      alert('Verwijderen is mislukt')
    }
  }
  const navigate = useNavigate()



  const [lid, setLid] = useState();
  
  useEffect(() => {

    load(id);
  }, []);
  
  if(!lid) {
    return;
  }
    return <div id={lid.Id} className="ledenCard">
  <Pressable style={styles.cancel} onPress={() => navigate("/Leden/Adressen")}><Text style={styles.cancelText}>Annuleren</Text></Pressable>
  <View style={styles.container}> 
  <label>Voorletters: </label><TextInput editable maxLength={100} name="Voorletters" label="Voorletters" defaultValue={lid.Voorletters} onChangeText={newText => lid.Voorletters = newText}></TextInput>
  <label>Tussenvoegsel: </label><TextInput name="Tussenvoegsel" label="Tussenvoegsel" defaultValue={lid.Tussenvoegsel == null ? '' : lid.Tussenvoegsel} onChangeText={newText => lid.Tussenvoegsel = newText}></TextInput>
  <label>Achternaam: </label><TextInput name="Achternaam" label="Achternaam" defaultValue={lid.Achternaam} onChangeText={newText => lid.Achternaam = newText}></TextInput>
  <label>Adres: </label><TextInput name="Adres" label="Adres" defaultValue={lid.Adres} onChangeText={newText => lid.Adres = newText}></TextInput> 
  <label>Postcode: </label><TextInput name="Postcode" maxLength={7} label="Postcode" defaultValue={lid.Postcode} onChangeText={newText => lid.Postcode = newText}></TextInput> 
  <label>Plaats: </label><TextInput name="Plaats" label="Plaats" defaultValue={lid.Plaats} onChangeText={newText => lid.Plaats = newText}></TextInput> 
  <label>Telefoon:</label>  <TextInput name="Telefoon" label="Telefoon" defaultValue={lid.Telefoon} onChangeText={newText => lid.Telefoon = newText}></TextInput> 
  <br />
    <View style={styles.inputWrap}>
      <Pressable onPress={updateLid} style={styles.submit}><Text style={styles.text}>Opslaan</Text></Pressable>
      <Pressable onPress={() => { if (window.confirm('Weet u zeker dat u dit lid wilt verwijderen?')) verwijderLid() } } style={styles.delete}><Text style={styles.text}>Verwijder</Text></Pressable>
    </View>
    </View>
    
    </div>

    
}

