import React, { useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import {StyleSheet, Text, View, TextInput, Pressable} from 'react-native';
import { useKeycloak } from '@react-keycloak/web';


const styles = StyleSheet.create({
  
  container: {
    display: 'flex',
   
  },
  inputWrap: {
    flex: 1,
    flexDirection: "row",
    borderColor: "#cccccc",
    justifyContent: 'center',
    borderBottomWidth: 1,
    marginBottom: 10
  },
  submit: {
    height: '50px',
    width: '125px',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 3,
    marginTop: 15,
    textAlign: 'center',
    margin: '10px',
    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
    backgroundColor: 'green',
    padding: '5px',
  },  

  text: {
    fontSize: 16,
    color: 'white',
    fontStyle: 'bold',
  },

  delete: {
    height: '50px',
    width: '125px',
    alignItems: 'center',
    justifyContent: 'center',
    elevation: 3,
    marginTop: 15,
    margin: '10px',
    textAlign: 'center',

    border: 'none',
    borderRadius: '10px',
    textAlign: 'center',
    backgroundColor: 'red',
    padding: '5px',
  },

  cancel: {
    borderRadius: '4px',
    backgroundColor: 'gray',
    marginBottom: 10,
    width:80,
    alignItems: 'center',
    justifyContent: 'center',
  },

  cancelText: {
    fontSize: 16,
    color: 'white',
    fontStyle: 'bold',
  },

 
});


export default function LedenEditForm({id}) {
  const {keycloak} = useKeycloak();
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL; 
  const navigate = useNavigate()

   
  function addLid(){

    saveLid();
  }

  function saveLid() {
    try {
    var AuthorizationHeader = 'Bearer ' + keycloak.token; 

    const apiUrl = apiBaseUrl+"/leden/add/";

    var jsonMessage= 
    {
      'Voorletters': voorletters,
      'Tussenvoegsel': tussenvoegsel,
      'Achternaam': achternaam,
      'Adres': adres,
      'Postcode': postcode,
      'Plaats': plaats,
      'Telefoon': telefoon
    };

      fetch(apiUrl, {
          method: 'POST',
          withCredentials: true,
        
          headers: {
              'Authorization': AuthorizationHeader,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify(jsonMessage)
          },
          alert('Het nieuwe lid toegevoegd.'),
          navigate("/Leden/Adressen")
      );
      
    } catch(error) {
      console.log(error);
      alert('Opslaan is mislukt.');
    }
  }


  const[voorletters, setVoorletters] = useState();
  const[tussenvoegsel, setTussenvoegsel] = useState();
  const[achternaam, setAchternaam] = useState();
  const[adres, setAdres] = useState();
  const[postcode, setPostcode] = useState();
  const[plaats, setPlaats] = useState();
  const[telefoon, setTelefoon] = useState();

    return <div id='newLid' className="ledenCard">
      <Pressable style={styles.cancel} onPress={() => navigate("/Leden/Adressen")}><Text style={styles.cancelText}>Annuleren</Text></Pressable>
  
  <View>
  <label>Voorletters: </label><TextInput editable maxLength={100} name="Voorletters" label="Voorletters"onChangeText={newText => setVoorletters(newText)}></TextInput>
  <label>Tussenvoegsel: </label><TextInput name="Tussenvoegsel" label="Tussenvoegsel" onChangeText={newText => setTussenvoegsel(newText)}></TextInput>
  <label>Achternaam: </label><TextInput name="Achternaam" label="Achternaam" onChangeText={newText => setAchternaam(newText)}></TextInput>
  <label>Adres: </label><TextInput name="Adres" label="Adres" onChangeText={newText => setAdres(newText)}></TextInput> 
  <label>Postcode: </label><TextInput name="Postcode"  maxLength={7}  label="Postcode" onChangeText={newText => setPostcode(newText)}></TextInput> 
  <label>Plaats: </label><TextInput name="Plaats" label="Plaats" onChangeText={newText => setPlaats(newText)}></TextInput> 
  <label>Telefoon:</label>  <TextInput name="Telefoon" label="Telefoon" onChangeText={newText => setTelefoon(newText)}></TextInput> 
  <Pressable onPress={addLid} style={styles.submit}><Text style={styles.text}>Opslaan</Text></Pressable>

    </View>
    </div>
}

