import { useState, useEffect } from 'react'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';

import { useKeycloak } from '@react-keycloak/web'

export const useApollo = (baseURL, authenticated) => {
    const {keycloak, initialized} = useKeycloak()
    const [client, setClient] = useState({});
    
    useEffect(() => {
        const instance = new ApolloClient({
            uri: baseURL,
            headers: { authorization: authenticated ? `Basic `+process.env.REACT_APP_AUTH_PRIVATE+` ` : `Basic `+process.env.REACT_APP_AUTH_PUBLIC+` `, },
            cache: new InMemoryCache()
        })
    
        setClient({ instance })
    
        return () => {
          setClient({})
        }
      }, [baseURL, initialized, keycloak, keycloak.token])

    return client.instance
};