import React from "react";
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from "./components/Navbar";
import Page from "./components/Page";
import Kerktijden from "./components/Kerktijden";
import Kerkdienstgemist from "./components/Kerkdienstgemist";
import Footer from "./components/Footer";

import './Kerkdienstgemist.css';

export default function HomePage() {
  return (        
    <div className="page-container mb-3">
      <MDBContainer>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column w-100">
            <div className="p-2 w-100 navwrapper bg-light"><Navbar /></div>
            <div className="w-100"><Page url={"home"}/></div>
            <div className="d-flex flex-row">              
              
              <div className="d-flex flex-column mb-3 agenda w-25">
                <div className="p-2 w-25"><h3>Kerktijden</h3></div>
                <Kerktijden numberOfWeeks="2" />
              </div>      
              <div className="d-flex flex-column mb-3  meekijken w-75">
              <div className="p-2 w-100"><h3>Meeluisteren</h3></div>
                <div className="meeluisteren">
                    <p>Tijdens de diensten die in ons kerkgebouw gehouden worden is het mogelijk om live mee te kijken en luisteren. </p>
                    <Kerkdienstgemist />
                  </div>
              </div>     
            </div>
            
            <Footer />
        </div>
      </div>
      </MDBContainer>
    </div>
  );
}