import React, { useState, useEffect }  from 'react';
import Moment from 'moment';
import 'moment/locale/nl';



export default function Kerktijden({numberOfWeeks}) {
  
    Moment().locale('nl')
    const [appState, setAppState] = useState({
      loading: false,
      repos: null,
    });
    useEffect(() => {
        
        setAppState({ loading: true });
        const apiUrl = "https://api.kerktijden.nl/api/gathering/GetGatheringsForWidget?communityId=305&weeks="+numberOfWeeks;
        fetch(apiUrl)
          .then((res) => res.json())
          .then((repos) => {
            //console.log(repos);
            setAppState({ loading: false, repos: repos });
          });
      }, [setAppState]
      
      );

      if(appState.loading) {
        return <div id='kerktijdenWidgetLoad'>Kerktijden laden</div>
      } else {
      return ( <div id='kerktijdenWidget' className='kerktijdenWidget'>
        
     
      { appState.repos && appState.repos.map(item => 
      <div className="kerktijdenItem" key ={item.id}>
         <div><b> {item.gatheringTypes[0].name}</b></div>
        <div> {Moment(item.startTime).format("LLLL")} </div>
        <div>{item.notition} 
        {item.persons.length > 0 && item.persons[0].status.statusShort+" "} 
        {item.persons.length > 0 && item.persons[0].initials+" "} 
        {item.persons.length > 0 && item.persons[0].lastname} </div>
       
      </div>
      )} 
       </div>
        );
    }  
}

