import React, { useEffect, useState, useRef } from "react";
import {
  StyleSheet,
  Button,
  View,
  SafeAreaView,
  Text,
  Alert,
} from 'react-native';
import ReactToPrint from "react-to-print";
import { gapi } from "gapi-script";
import Moment from 'moment';
import 'moment/locale/nl';
import { LuPrinter } from "react-icons/lu";

//let selectedMonth = Moment(Moment.now()).startOf('month');



function Events() {
  const componentRef = useRef();

  const [startDate, setStartDate] = useState(Moment(Moment.now()).startOf('month'));
  const [events, setEvents] = useState();
  const [numberOfMonths, setNumberOfMonths] = useState(1);

  const calendarID = process.env.REACT_APP_CALENDAR_ID;
  const calenderIDKerktijden = 'vmai9lgj7eet4selr7uhpcl1pvuknvrd@import.calendar.google.com';
  const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;


  useEffect(() => {
    const loadEvents = async() => { 
        getEvents(calendarID, calenderIDKerktijden, apiKey, startDate); 
    };
   
    loadEvents();
    
  }, [startDate, numberOfMonths]); 

  function previousMonth(){
    const previousMonth = startDate.add(-1, 'months');
    setStartDate(Moment(previousMonth));
    getEvents(calendarID, calenderIDKerktijden, apiKey, startDate);
  }

  function nextMonth(){
    const previousMonth = startDate.add(1, 'months');
    setStartDate(Moment(previousMonth));
    getEvents(calendarID, calenderIDKerktijden, apiKey, startDate);
  }

  const getEvents = (calendarID, calendarIDKerktijden, apiKey, startDate) => {
   
    async function getAllEvents() {
     
      const timeMin =  Moment.utc(startDate).format();
      const tmpStartDate = startDate.clone();
      const timeMax = Moment.utc(Moment(tmpStartDate.add(numberOfMonths-1, 'months')).endOf('month')).format();

      let internalEvents = [];
      let publicEvents = [];
      setEvents(null);
      await gapi.client
        .init({
          apiKey: apiKey,
        })
        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/calendars/${calendarIDKerktijden}/events?singleEvents=true&orderBy=startTime&timeMin=${timeMin}&timeMax=${timeMax}`,
          });
        })
        .then(
          (response) => {
            let events = response.result.items;
            publicEvents = events;
          },
          function (err) {
            return [false, err];
          }
        );

      await gapi.client
        .init({
          apiKey: apiKey,
        })
        .then(function () {
          return gapi.client.request({
            path: `https://www.googleapis.com/calendar/v3/calendars/${calendarID}/events?singleEvents=true&orderBy=startTime&timeMin=${timeMin}&timeMax=${timeMax}`,
          });
        })
        .then(
          (response) => {
            let events = response.result.items;     
            internalEvents = events;     

          },
          function (err) {
            return [false, err];
          }
        );

        let allEvents = internalEvents.concat(publicEvents);

        allEvents.sort((a, b) => (
          (a.start.dateTime === undefined ? a.start.date : a.start.dateTime) > (b.start.dateTime === undefined ? b.start.date : b.start.dateTime)
          ) ? 1 : -1)
        setEvents(allEvents);
    }

    gapi.load("client", {
      callback: function() {        
        getAllEvents();           
     
      }
      });

 
     
  };
 
  function checkResponse(events) {
      if (events) {
        return <div>

        <div className='eventsHeader'>
          <div className="eventSelectors">
            <div className='eventsMonthNavigation'><a href="#" onClick={previousMonth}>Vorige maand</a> </div>
            <div className='eventsCurrentMonth'>{Moment(startDate).format("MMMM YYYY")} </div>
            <div className='eventsMonthNavigation'><a href="#" onClick={nextMonth}>Volgende maand</a></div>
          </div>
          <div className="eventSettings">
          
            <div className='eventsNumberOfMonths'> 
                Toon 
                <select value={numberOfMonths} onChange={e =>  setNumberOfMonths(e.target.value)}>
                  <option value='1'>1</option>
                  <option value='2'>2</option>
                  <option value='3'>3</option>
                </select>
                maand(en)
            </div>
            <div className='eventsPrint'> 
          <ReactToPrint       
              bodyClass = "EventsPrintView"
              pageStyle=" margin: 20mm; "
              documentTitle="Agende Gereformeerde Gemeente Zoetermeer"
              content={() => componentRef.current}
              trigger={() => (
                    <button className="printButton"><LuPrinter /></button>
                )}
          />
         </div>
          </div>
          </div>
          <div ref={componentRef}>
          <table  width="100%">
            <tr>
              <th width="27%">Datum</th>
              <th width="10%">Tijd</th>
              <th width="33%">Activiteit</th>
              <th width="30%">Locatie</th>
            </tr>
          
          
                        
              {events?.map((event) => (
                
                <tr key={event.id}> 
                  <td>{event.start.dateTime === undefined ? Moment(event.start.date).format("dddd DD MMMM YYYY") : Moment(event.start.dateTime).format("dddd DD MMMM YYYY")}</td>
                  <td>{event.start.dateTime === undefined ? '' : Moment(event.start.dateTime).format("LT")}</td>
                  <td>{event.summary === 'Leesdienst' ? event.description : event.visibility === 'private' ? 'Gereserveerd' : event.summary }</td>
                  <td>{event.location === 'Vredekerk, Zwaardslootseweg 44, Zoetermeer' ? '' : event.location }</td>
                </tr>
              ))}
            
          </table>
          </div>
          <div className='eventsLegendWrapper'>
        
              A kerkzaal -
              B consistorie -
              C zaal groot -
              D zaal klein -
              E ver.boven gr. -
              F ver.boven kl. -
              G ver.onder
          </div>
        </div>;
    } else {
        return null;
    }

}

  return (
    
  <div >
     {checkResponse(events)}   
    </div>
  );
}
 
export default Events;


