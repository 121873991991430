import React, { useEffect, useState } from "react";

import 'moment/locale/nl';
import { useKeycloak } from '@react-keycloak/web';

let defaultFilter = 'a';

function Ledenlijst() {
  const [filter, setFilter] = useState(defaultFilter);
  const [leden, setLeden] = useState(null);
 
  useEffect(() => {
    try {
      const loadLeden = async() => { getLeden(filter); };
   
      loadLeden();
    } catch (error){
      console.error(error);
      setLeden(null);
    }
  },[filter]);


  

  function changeFilter(newFilter){
    
    setFilter(newFilter);
    //getLeden(newFilter);
  }

 const {keycloak} = useKeycloak();

  const getLeden = (filter) => {

      try {
      var AuthorizationHeader = 'Bearer ' + keycloak.token; 

       const apiUrl = process.env.REACT_APP_API_BASEURL+"/leden?filter="+filter;
        fetch(apiUrl, {
            method: 'GET',
            withCredentials: true,
            //credentials: 'include',
            headers: {
                'Authorization': AuthorizationHeader,
                'Content-Type': 'application/json'
            }}).then((res) => res.json())
          .then((repos) => {
            setLeden(repos);
            //console.log(repos);
           // setAppState({ loading: false, repos: repos });
          });
        } catch (error){
          console.error(error);
          setLeden(null);
        }
  };
 
  function checkResponse(leden) {

var divFilter =  <div className='ledenFilter'>
<button onClick={() => changeFilter('a')}>A</button>
<button onClick={() => changeFilter('b')}> B</button>
<button onClick={() => changeFilter('c')}> C</button>
<button onClick={() => changeFilter('d')}> D</button>
<button onClick={() => changeFilter('e')}> E</button>
<button onClick={() => changeFilter('f')}> F</button>
<button onClick={() => changeFilter('g')}> G</button>
<button onClick={() => changeFilter('h')}> H</button>
<button onClick={() => changeFilter('i')}> I</button>
<button onClick={() => changeFilter('j')}> J</button>
<button onClick={() => changeFilter('k')}> K</button>
<button onClick={() => changeFilter('l')}> L</button>
<button onClick={() => changeFilter('m')}> M</button>
<button onClick={() => changeFilter('n')}> N</button>
<button onClick={() => changeFilter('o')}> O</button>
<button onClick={() => changeFilter('p')}> P</button>
<button onClick={() => changeFilter('q')}> Q</button>
<button onClick={() => changeFilter('r')}> R</button>
<button onClick={() => changeFilter('s')}> S</button>
<button onClick={() => changeFilter('t')}> T</button>
<button onClick={() => changeFilter('u')}> U</button>
<button onClick={() => changeFilter('v')}> V</button>
<button onClick={() => changeFilter('w')}> W</button>
<button onClick={() => changeFilter('x')}> X</button>
<button onClick={() => changeFilter('y')}> Y</button>
<button onClick={() => changeFilter('z')}> Z</button>
</div>
var canEdit = false;
canEdit = keycloak.hasRealmRole('Ledenadmin');


    if (leden && leden.length >0) {
        return <div>
            {canEdit ?<div className='ledenAddButton'> <a href={'/Leden/Add/'}>Voeg nieuw lid toe</a></div> : ''}
            {divFilter}
            <br />
            <div  id='leden' className='leden'>
            { leden?.map(item => 
     
      <div key={item.Id} id={item.Id} className="ledenCard">
        <div key={'details-'+item.Id}  id={'details-'+item.Id}  className="ledenCardDetails"><b>{item.Voorletters} {item.Tussenvoegsel === null ? "" : (item.Tussenvoegsel + ' ')}{item.Achternaam}</b></div>
        <div key={'details-edit-'+item.Id} id={'details-edit-'+item.Id} className="ledenCardDetailsEdit">{canEdit ? <a  key={'details-edit-'+item.Id} href={'/Leden/Edit/'+item.Id}>Wijzigen</a> : ''}</div>
        <br />
        {item.Adres} <br />
        {item.Postcode} {item.Plaats} <br />
        {item.Telefoon} 

      </div>
      )} </div>
        
        </div>
    } else {
        return <div>{divFilter}<div className='noResult'>Geen leden gevonden met '{filter.toUpperCase()}' als beginletter van de achternaam.</div></div>;
    }

}
 
  return (<div>
    {checkResponse(leden)}
    </div>
  );
}
 
export default Ledenlijst;