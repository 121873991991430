import React from "react";
import { gql, useQuery } from "@apollo/client";
import * as DOMPurify from 'dompurify';
import { isNullish } from "@apollo/client/cache/inmemory/helpers";
import NotFound from "./404";
import Error from "./500";


const GET_ALL_POSTS = gql`
  query GetPage($url: String!) {
    pageBy(uri: $url) {
      title,
      content
    }
  }
`;



export default function Page({url}) {
  url = ('' +url).toLowerCase();
  const { loading, error, data } = useQuery(GET_ALL_POSTS, {variables: {url}});

  if (loading) return <p>De pagina wordt geladen...</p>;
  if (error) console.error(error);
  if (error) return  (
    <div className="page">
        <Error />
    </div>
  )
  const postsNotFound = isNullish(data.pageBy);
  if (postsNotFound) {
    return (
      <div className="page">
        <NotFound />
      </div>
    )
  }

  var title = DOMPurify.sanitize(data.pageBy.title, { USE_PROFILES: { html: true } });
 // let content = DOMPurify.sanitize(data.pageBy.content, { USE_PROFILES: { html: true } });
  return (

    <div className="page">
        <div className="title"><h1 dangerouslySetInnerHTML ={{ __html: title}} /></div>
        <div className="content" dangerouslySetInnerHTML ={{ __html: data.pageBy.content}} />
    </div>
  );
}