import React from "react";
import { useParams } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from "./components/Navbar";
import NotFoundPage from "./components/NotFoundPage";
import Footer from "./components/Footer";


export default function Content() {
    let { url } = useParams();
  return (        
    <div className="page-container">
      <MDBContainer>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column mb-3 w-100">
          <div className="p-2 w-100 navwrapper bg-light"><Navbar /></div>
            <div className="w-100 pageWrapper"><NotFoundPage/></div>
        </div>
      </div>
      <Footer />
      </MDBContainer>
     
    </div>
  );
}