import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function KerkdienstgemistTerugkijken() {
  return (

<div className="page">
    <div className="title"><h1>Diensten</h1></div>
        <div className="content">
        <Tabs>
          <TabList>
            <Tab>Beelduitzendingen</Tab>
            <Tab>Audiouitzendingen</Tab>
          </TabList>

          <TabPanel>
              <div className="kerkdienstgemistwidget">
                  <div className="kdgm-responsive-player-gemist player-16by9 player">
                      <iframe title="kerkdienstgemistwidget" scrolling="no" width="100%" 
                      borderwidth="0" borderheight="0" src="//kerkdienstgemist.nl/playlists/10401/embed?media=video&per_page=4&playerheight=250&responsive=true&aspect-ratio=16-9" 
                      allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true">
                      </iframe>
                      </div>
              </div>   
          </TabPanel>
          <TabPanel>
              <div className="kerkdienstgemistwidgetAudio">
                  <div className="kdgm-responsive-player-gemist player-16by9 player">
                      <iframe title="kerkdienstgemistwidget" scrolling="no" width="100%" 
                      borderwidth="0" borderheight="0" src="//kerkdienstgemist.nl/playlists/10401/embed?media=audio&per_page=4&playerheight=80&responsive=true&aspect-ratio=16-9" 
                      allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true">
                      </iframe>
                      </div>
              </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>  
  );
}