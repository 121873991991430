import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default function Kerkdienstgemist() {
  return (
    <Tabs>
    <TabList>
      <Tab>Beelduitzendingen</Tab>
      <Tab>Audiouitzendingen</Tab>
    </TabList>

    <TabPanel>
    <div className="kerkdienstgemistwidget">
        <div className="kdgm-responsive-player player-16by9 player">
            <iframe title="kerkdienstgemistwidget" width="100%" height="360px"
            borderwidth="0" borderheight="0" src="//kerkdienstgemist.nl/streams/1520986/embed" 
            allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true" 
            ></iframe>
            </div>
    </div>
    </TabPanel>
          <TabPanel>
    <div className="kerkdienstgemistwidget">
        <div className="kdgm-responsive-player player-16by9 player">
            <iframe title="kerkdienstgemistwidget" width="100%" height="100px"
            borderwidth="0" borderheight="0" src="//kerkdienstgemist.nl/streams/1520953/embed" 
            allowFullScreen={true} mozallowfullscreen="true" webkitallowfullscreen="true" 
            ></iframe>
            </div>
    </div>
    </TabPanel>
        </Tabs>
  );
}   
