import React, { useEffect, useState } from "react";
import {StyleSheet, Text, View, TextInput, Pressable} from 'react-native';
import { Picker } from '@react-native-picker/picker';



export default function ContactForm({prefilledreceiver}) {
//const RenderedContactForm = () => {
  if(prefilledreceiver === undefined) {
    prefilledreceiver = 'Scriba';
  }

  const [receiver, setReceiver] = useState(prefilledreceiver);
  const [name, setName] = useState();
  const [nameValid, setNameValid] = useState();
  const [email, setEmail] = useState();
  const [emailValid, setEmailValid] = useState();
  const [subject, setSubject] = useState();
  const [subjectValid, setSubjectValid] = useState();
  const [message, setMessage] = useState();
  const [messageValid, setMessageValid] = useState();
  
  const apiBaseUrl = process.env.REACT_APP_API_BASEURL; 

  const [send, setSend] = useState(false);



  var content = send ? 
  
  <div className='emailSend'>
     <View style={styles.container}>
    <Text>Bedankt voor uw bericht. We nemen zo spoedig mogelijk contact op.</Text>
    <Pressable onPress={sendOther}><Text>Klik hier als u nog een bericht wilt versturen</Text></Pressable>
    </View>
  </div> 
  :
  <div>
  <View  style={styles.container}>
  <label>Verstuur bericht naar: </label>
  <Picker style={styles.input} value={receiver} onValueChange={value => setReceiver(value)}>
  <Picker.Item label="Scriba" value="Scriba" />
  <Picker.Item label="Predikant" value="Predikant" />
  <Picker.Item label="Webbeheer" value="Webbeheer" />
  <Picker.Item label="Evangelisatie" value="Evangelisatie" />
  </Picker >
    <label>Uw naam: </label>
    <label style={nameValid === false ? styles.invalid : styles.valid}>Naam is verplicht</label>
    <TextInput editable maxLength={200} name="Naam" label="Naam" style={styles.input} value={name} onChangeText={text => validateNameInput(text)}></TextInput>
     <label>Uw email: </label>
    <label style={emailValid === false ? styles.invalid : styles.valid}>Email is verplicht</label>
    <TextInput editable maxLength={200} name="Email" label="Email"  style={styles.input} inputMode="email" value={email} onChangeText={text => validateEmailInput(text)}></TextInput>
    <label>Onderwerp: </label>
    <label style={subjectValid === false ? styles.invalid : styles.valid}>Onderwerp is verplicht</label>
    <TextInput editable maxLength={400} name="Onderwerp" label="Onderwerp"  style={styles.input} value={subject} onChangeText={text => validateSubjectInput(text)}></TextInput>
    <label>Bericht: </label>
    <label style={messageValid === false ? styles.invalid : styles.valid}>Bericht is verplicht</label>
    <TextInput multiline='true' numberOfLines='10' editable maxLength={2000} name="Bericht" label="Bericht" style={styles.input} value={message} onChangeText={text => validateMessageInput(text)}></TextInput>
   <Pressable onPress={sendMessage}><Text style={styles.submit}>Verstuur bericht</Text></Pressable>

  </View>
  </div>

 return content;

 function sendOther() {
  setMessage(null);
  setSend(false);
 }

 function validateNameInput(text) {
    setName(text);
    if(text === '' || text === undefined  || text === null ) {
      setNameValid(false);  
      return;
    }
    setNameValid(true);  
 }

 function validateEmailInput(text) {
  setEmail(text);
  if(text === '' || text === undefined  || text === null ) {
    setEmailValid(false);  
    return;
  }
  setEmailValid(true);  
}

function validateSubjectInput(text) {
  setSubject(text);
  if(text === '' || text === undefined  || text === null ) {
    setSubjectValid(false);  
    return;
  }
  setSubjectValid(true);  
}

function validateMessageInput(text) {
  setMessage(text);
  if(text === '' || text === undefined  || text === null ) {
    setMessageValid(false);  
    return;
  }
  setMessageValid(true);  
}

 function validateAll() { 
  var valid = ( nameValid === true && emailValid === true && subjectValid === true && messageValid === true);
  if(valid) {
    return valid;
  }
  validateNameInput(name);
  validateEmailInput(email);
  validateSubjectInput(subject);
  validateMessageInput(message);

  return valid;

 }


function sendMessage() {
  var valid = validateAll();
    if(valid) {

    var jsonMessage= 
      {
      receiver,
      name,
      email,
      subject,
      message
    };


    const apiUrl = apiBaseUrl+"/message/";
    try {
    fetch(apiUrl, {
        method: 'POST',
        withCredentials: false,
        body: JSON.stringify(jsonMessage),
        headers: {
            'Content-Type': 'application/json'
        }}).then(setSend(true))
        .catch((error) => {

          console.error('An error occurred:', error);
      
        })
      ;
    } catch(error) {
      console.log(error);
    }

  }
}

};


const styles = StyleSheet.create({
    container: {
      marginTop: 50,
      backgroundColor: 'white',
      width: '60%',
      marginLeft: '20%',
      padding: 10,
      fontWeight: 'bold',
      borderRadius: 10,
      fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif ',
    },
    input: {
      fontSize: 16,
      border: '1px solid'
    },
    submit: {
      marginTop: 15,
      fontSize: 20,
      border: '2px solid',
      borderRadius: '5px',
      textAlign: 'center',
      backgroundColor: 'lightGray',
    },
    valid: {
      display: 'none',
    },
    invalid: {
      color: 'red',
      fontSize: 10,
    }
  });

  //export default ContactForm;