import React from "react";
import { useParams } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from "./components/Navbar";
import ContactForm from "./components/ContactForm";
import Page from "./components/Page";
import Footer from "./components/Footer";


export default function Contact() {
  let { receiver } = useParams();
  return (        
    <div className="page-container">
      <MDBContainer>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column mb-3 w-100">
          <div className="p-2 w-100 navwrapper bg-light"><Navbar /></div>
            <div className="w-100 pageWrapper">
                { <Page url='Contact'/> }
            <ContactForm prefilledreceiver={receiver} />
            </div>
        </div>
      </div>
      <Footer />
      </MDBContainer>
     
    </div>
  );
}