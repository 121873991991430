
import React from "react";

export default function Footer() {
  

  return (
    <div>
         <div className="d-flex flex-row footer"> 
            <div className="d-flex flex-column w-100 txt-normal center">
                Vredekerk - Zwaardslootseweg 44 - 2726 ZZ - Zoetermeer<br />
                <a href='https://goo.gl/maps/hxb9VxxJtEufvQ6Y6'>route</a></div>
         
            </div>
    </div>
    );
}