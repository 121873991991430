import React from "react";
import { useParams } from "react-router-dom";
import { MDBContainer } from 'mdb-react-ui-kit';
import Navbar from "./components/Navbar";
import LedenAddForm from "./components/LedenAddForm";
import Footer from "./components/Footer";


export default function Content() {


  let { id } = useParams();
  return (        
    <div className="page-container">
      <MDBContainer>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-column mb-3 w-100">
          <div className="p-2 w-100 navwrapper bg-light"><Navbar /></div>
            <div className="w-100 pageWrapper">
            <div className="page">
                <div className="title"><h1>Leden</h1></div>

                <b>Toevoegen van een nieuw lid</b><br />
                Voer onderstaande gegevens in om een nieuw lid toe te voegen.
                <div className="content"><LedenAddForm/></div>
              </div>
            </div>
        </div>
      </div>
      <Footer />
      </MDBContainer>
      
    </div>
  );
}