import React from 'react';

import { useKeycloak } from '@react-keycloak/web';

import {
  MDBNavbarLink
} from 'mdb-react-ui-kit';


export default function UserInfo() {
    const {keycloak} = useKeycloak();


    return (
        <div className="userInfo">
       {!keycloak.authenticated && ( 
        
          <>
            <MDBNavbarLink href='#' onClick={() => keycloak.login()}>
              <span className='navbar-text'>Inloggen</span>
            </MDBNavbarLink></>
        
       )}
       {!!keycloak.authenticated && (
              <><span className='navbar-text'>           <b>Ingelogd als:
              <br />{keycloak.tokenParsed?.preferred_username}</b></span>
              <MDBNavbarLink href='#' onClick={() => keycloak.logout()}>         
                <span className='navbar-text'>Afmelden</span>
                </MDBNavbarLink>
                <br />
                <MDBNavbarLink href='https://identity.ggzoetermeer.nl/realms/ggzoetermeer/protocol/openid-connect/auth?client_id=ggzoetermeer&redirect_uri=https://www.ggzoetermeer.nl&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD'>         
                <span className='navbar-text'>Wachtwoord wijzigen</span>
                </MDBNavbarLink>
                </>
       )}
       </div>
    )
}